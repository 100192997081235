<temaplte>
  <div class="slider">Slider</div>
</temaplte>

<script>
export default {
  name: 'WebsitesSlider',
  data() {
    return {
      // swiperOptionSlider: {
      //   slidesPerView: 1,
      //   speed: 600,
      //   autoplay: true,
      // },
    };
  },
};

</script>

<style lang="scss">
.slider {
  height: 832px;
}
</style>
